<template>
  <div class="price_name_box">
    <div class="price_name">
      <span class="price_style_name">活动价¥</span>
      <span class="price_style">{{ flashGoodsInfo.goodVipPrice }}</span>
      <span class="price_style_title">{{ flashGoodsInfo.hiddenRemind }} ¥{{ flashGoodsInfo.hiddenPrice }}</span>
    </div>
    <h1>{{ productInfo.goodName }}</h1>
    <h2>{{ productInfo.goodsInfo }}</h2>
    <span class="price_list_box"> 供应商：{{ productInfo.goodSupplier }}</span>
  </div>
</template>

<script>
export default {
  name: 'GoodPriceName',
  props: {
    'flashGoodsInfo': {
      type: null,
      default: null
    },
    'productInfo': {
      type: null,
      default: null
    }
  }

}
</script>

<style scoped lang="less">
.price_name_box {
  text-align: left;
  padding: 12px;
  box-sizing: border-box;
  background: #fff;

  .price_name {
    .price_style_name {
      color: #e8480a;
    }

    .price_style {
      font-size: 18px;
      color: #e8480a;
      margin: 0 2px;
      font-weight: 500;
    }

    .price_style_title {
      color: #999;
      text-decoration: line-through;
    }
  }

  h1 {
    font-size: 16px;
    margin: 12px 0;
  }

  h2 {
    margin-top: 6px;
    font-size: 12px;
    color: #999;
    margin-bottom: 12px;
  }

  .price_list_box {
    background: red;
    color: #fff;
    font-size: 12px;
    border-radius: 12px;
    padding: 3px 8px;
    box-sizing: border-box;
  }

}

</style>
