<template>
  <div class="goods_pt_main">
    <skeleton v-if="!productInfo" type="detail" />
    <!-- 顶部和底部 -->
    <div v-else>
      <openApp
        :showtig="true"
        :goods-status="goodsStatus"
        :goods-info="productInfo"
        :activity-id="activityId"
        :flash-info="flashGoodsInfo"
      />

      <!--1 banner图片 -->
      <van-swipe :autoplay="3000" indicator-color="#ED2E2E">
        <van-swipe-item v-for="(image, index) in productInfo.banner" :key="index">
          <img v-lazy="image" class="banner">
        </van-swipe-item>
      </van-swipe>
      <!--2 价格 名字 -->
      <goodPriceName :product-info="productInfo" :flash-goods-info="flashGoodsInfo" />
      <!--3 规格 地址 运费-->

      <googAddresName :goods-status="goodsStatus" :product-info="productInfo" :area-info="areaInfo" />
      <!--4 评论-->
      <gooogComment :product-info="productInfo" :flash-goods-info="flashGoodsInfo" />
      <!--5 推荐-->
      <groupDeRecommend :mend-goods="mendGoods" />
      <!--6 商品详情-->
      <div class="goods_good_content">商品详情</div>
      <div style="width: 100%;text-align: left" v-html="productInfo.goodContent" />

      <!--7 确定按钮-->
      <!-- <div class="goods_good_btn">
        <div class="goods_good_bottom">
          <div @click="getProduct()">购买送3成长值</div>
          <div>分享赚 ¥0.35</div>
        </div>
      </div> -->

    </div>

  </div>
</template>

<script>

import goodPriceName from '@/component/GoodsDetail/goodPriceName'
import groupDeRecommend from '@/component/GoodsDetail/groupDeRecommend'
import googAddresName from '@/component/GoodsDetail/googAddresName'
import gooogComment from '@/component/GoodsDetail/gooogComment'
import OpenApp from '@/component/GoodsDetail/goodsTopBtm'
import skeleton from '@/component/skeleton/index'
import Vue from 'vue'
import { ActionSheet, Icon, Lazyload, Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload).use(Icon).use(ActionSheet)
import { flashDetail, recommendGoods } from '@/services/api'

export default {
  name: 'GoodsDetailXsbj',
  components: {
    groupDeRecommend,
    goodPriceName,
    googAddresName,
    gooogComment, OpenApp, skeleton
  },
  data() {
    return {
      // 占空

      activityId: '',
      goodsStatus: 9,
      goodsInfo: {}, // 商品信息首屏
      productInfo: null, // data
      mendGoods: {}, // 推荐list
      areaInfo: {}, // 地址list
      assembleInfo: {},
      flashGoodsInfo: null,
      goodinfo: null
    }
  },
  mounted() {
    this.dataAll()
    this.dataList()
  },
  methods: {
    dataAll: function() {
      const data = {
        goodsId: this.$route.query.goodsId || this.$route.query.product_id || 0, // 产品ID
        skuId: this.$route.query.skuid || this.$route.query.product_sku_id || 0,
        activityId: this.$route.query.activityId,
        uid: window.localStorage.getItem('uid') || 0 // 用户ID
      }

      flashDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          const data = res
          this.productInfo = data.productInfo
          this.flashGoodsInfo = data.FlashGoodsInfo
          this.assembleInfo = data.assembleInfo
        }
      })
    },

    // 推荐 list
    dataList: function() {
      const data = {
        goodsId: this.$route.query.goodId,
        uid: this.$route.query.uid
      }
      recommendGoods(data).then((res) => {
        if (Number(res.code) === 200) {
          this.mendGoods = res.data
        }
      })
    },
    // buy btn
    getProduct: function() {
      const data = {

        goodId: 2111351,
        skuId: 16087210
      }
      this.goodinfo = data
      this.$store.commit('changeShowSku', true)
    }

  }
}
</script>

<style lang="less" scoped>
.goods_pt_main {
  background: #f6f6f6;
  margin-bottom: 12px;

  .goods_good_content {
    margin-top: 6px;
    background: #fff;
    padding: 12px 0;
    box-sizing: border-box;
  }

  .goods_good_btn {
    width: 100%;
    height: 46px;
    box-sizing: border-box;
    border-top: 1px solid #f8f8f8;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;

    .goods_good_bottom {
      width: 100%;
      height: 46px;
      margin: 2px auto;
      background: #f85a03;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 16px;
      color: #fff;

      div {
        flex: 1;
        height: 46px;
        line-height: 46px;
      }

      div:nth-child(2) {
        background: #eca008;
      }
    }
  }

}

</style>
