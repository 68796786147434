<template>
  <div v-if="productInfo.comment.comment_total!=0" class="gooog_comment">
    <div class="gooog_comment_name">
      <span>商品评价</span>
      <span class="gooog_comment_nub"> ({{ productInfo.comment.comment_total }}条)</span>
      <span class="gooog_comment_r" @click="googCommentList(flashGoodsInfo.goodId)">
        好评率{{ productInfo.comment.good_favourable }}>
        <span> > </span>
      </span>
    </div>
    <!--list-->
    <div class="gooog_comment_list">
      <div v-for="(item,index) in productInfo.comment.result" :key="index" class="gooog_comment_box">
        <div class="gooog_comment_box_son">
          <div class="gooog_comment_l">
            <img :src="item.litpic" alt="">
          </div>
          <div class="gooog_comment_l">
            <h4>{{ item.nickname }}</h4>
            <h5 v-for="(i,n) in item.goods_score" :key="n">
              <img class="gooog_comment_image" src="../../assets/images/star.png" alt="">
            </h5>
          </div>
        </div>
        <h3>{{ item.comment }}</h3>
        <h6>{{ item.goods_spec }}</h6>
      </div>
      <div class="gooog_comment_look">
        <span @click="googCommentList(flashGoodsInfo.goodId)">查看全部评价</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GooogComment',
  props: {
    'productInfo': {
      type: null,
      default: null
    },
    'flashGoodsInfo': {
      type: null,
      default: null
    }
  },
  mounted() {
  },
  methods: {
    googCommentList: function(id) {
      this.$router.push({
        path: '/googCommentList',
        query: {
          goodId: id
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.gooog_comment {

  background: #fff;
  margin-top: 6px;

  .gooog_comment_name {
    overflow: hidden;
    padding: 12px;
    text-align: left;

    .gooog_comment_nub {
      color: #999;
      font-size: 12px;
    }

    .gooog_comment_r {
      float: right;
      color: red;
      font-size: 12px;

      span {
        color: #666;
      }
    }
  }

  .gooog_comment_list {
    background: #fff;

    .gooog_comment_box:nth-child(1) {
      border-bottom: 1px solid rgba(0, 0, 0, .05);
    }

    .gooog_comment_box {
      text-align: left;
      padding: 12px;
      box-sizing: border-box;

      .gooog_comment_box_son {
        overflow: hidden;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .gooog_comment_l {
          float: left;
          margin-right: 4px;

          h4 {
            font-size: 12px;
            font-weight: 500;
          }

          h5 {
            display: inline-block;
          }

          .gooog_comment_image {
            width: 12px;
            height: 12px;
          }
        }
      }

      h3 {
        margin-top: 12px;
        font-size: 12px;
      }

      h6 {
        margin-top: 10px;
        font-size: 12px;
        color: #999;
      }
    }

    .gooog_comment_look {
      margin: 28px 28px 0 28px;
      padding-bottom: 16px;
      box-sizing: border-box;

      span {
        border: 1px solid rgba(0, 0, 0, 0.36);
        text-align: center;
        padding: 6px 12px;
        border-radius: 16px;
        box-sizing: border-box;
        font-size: 12px;
      }
    }

  }
}
</style>
